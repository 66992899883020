
            @import 'src/styles/mixins.scss';
        
.form-modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // inset: 0;
    width: 100%;

    z-index: 1000;
    animation: slide-bottom 0.7s;

    @include md {
        max-width: 650px;
        -webkit-animation: fade-slide-in 0.7s;
        animation: fade-slide-in 0.7s;
    }
    @media screen and (max-width: 767px) {
        padding: 20px;
        padding-top: 45px;
        inset: 0;
        transform: translate(0, 0);
    }

    .form-modal-wrapper {
        position: relative;
        box-shadow: 0px 0px 15px 7px rgba(0, 0, 0, 0.1);
        border: 1px solid lightgray;
        border-radius: 30px;
        padding: 4rem;

        max-height: calc(100vh - 70px);
        overflow-y: auto;
        overflow-x: hidden;

        min-height: 500px;
        background-color: white;
        @media screen and (max-width: 767px) {
            padding: 3rem 10px;
            left: 0;
        }
    }

    .back-button {
        font-size: 12px;
        font-weight: 300;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        margin: 0 auto;
        margin-top: 20px;
        position: absolute;
        left: 20px;
        bottom: 10px;
        .arrow-back {
            box-sizing: border-box;
            position: relative;
            display: block;
            width: 14px;
            height: 14px;
            border: 2px solid transparent;
            border-radius: 100px;
            margin-right: 3px;
        }

        .arrow-back::after {
            content: "";
            display: block;
            box-sizing: border-box;
            position: absolute;
            width: 8px;
            height: 8px;
            border-bottom: 2px solid;
            border-left: 2px solid;
            transform: rotate(45deg);
            right: 0px;
            top: 1px;
        }
    }

    .form-modal-close-button {
        color: white;
        position: absolute;
        font-weight: 300 !important;
        top: 15px;
        right: 20px;

        button {
            svg {
                width: 25px;
                height: 25px;
            }
            margin-left: auto;
        }
        @include md {
            top: 0px;
            right: -35px;
        }
    }
}

@media screen and (max-width: 767px) {
    @-webkit-keyframes slide-bottom {
        0% {
            transform: translate(0, -100%);
        }
        100% {
            transform: translate(0, 0);
        }
    }
    @keyframes slide-bottom {
        0% {
            transform: translate(0, -100%);
        }
        100% {
            transform: translate(0, 0);
        }
    }
}

@keyframes fade-slide-in {
    0% {
        opacity: 0.5;
        transform: translate(-50%, -60%);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
}

.inner {
    overflow: hidden;
}
