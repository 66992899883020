
            @import 'src/styles/mixins.scss';
        
.steps-wrapper {
    display: flex;
    will-change: transform;
    transform: translate(0%, 0px);
    transition: transform 0.2s ease;
    height: auto; // internet explorer fallback
    height: fit-content;
    justify-content: stretch;
    .step {
        width: 100%;
        flex-shrink: 0;
        position: relative;
        z-index: 50;
        height: auto; // internet explorer fallback
        height: fit-content;
        padding: 0 2px;

        .title {
            font-size: 20px;
            font-weight: bold;
        }

        .description {
            margin: 10px 0;
        }

        .buttons {
            width: 100%;

            --margin: 20px;
            @include md {
                --margin: 30px;
            }
        }
    }
}
.conditionalLogic {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    max-width: 450px;
    label {
        color: #000 !important;
        font-weight: 700;
        font-size: 16px !important;
        @media screen and (max-width: 730px) {
            font-size: 14px !important;
        }
    }
    div[class^="styles_field-note"] {
        color: #000 !important;
    }
    div[class^="styles_error-message"],
    div[class*=" styles_error-message"] {
        left: 20px;
        bottom: 5px;
    }
}
.form-offers-popup {
    background-color: white !important;
    .offer {
        margin-top: 0;
        h2 {
            @apply text-base;
        }
    }
}
.form-inside-offers-container {
    background-color: white !important;
}
.sharedFieldsContainer {
    margin: 0 -10px;
}
.sharedFieldsWrapper {
    // max-width: 624px;
    margin: 25px auto 0;
    & > div {
        width: 100%;
        margin: 0;
        padding-left: 12px;
        padding-right: 12px;
        @media screen and (min-width: 730px) {
            // &:first-of-type {
            //     width: 100%;
            // }
            width: 50%;
            justify-content: space-between;
        }
    }
    .field-label {
        font-size: 14px !important;
    }
}
@keyframes loading {
    0% {
        width: 0%;
        background-color: #666;
    }
    100% {
        width: 100%;
        background-color: #666;
    }
}
.submit-button {
    background-color: black;
    color: white;
    width: 100%;
    min-height: 50px;
    border-radius: 3px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    transition: opacity 0.2s ease-in-out;

    .arrow {
        animation-name: flickIcon;
        animation-duration: 1.4s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-fill-mode: both;
        margin-left: 10px;
    }
    &:disabled {
        opacity: 0.6;
        pointer-events: none;
        cursor: default;
    }
    &:hover {
        opacity: 0.8;
    }
}

.first-step-button-merged {
    background-color: black;
    color: white;
    position: relative;
    bottom: 3px;
    right: 140px;
    width: 150px;
    min-height: 50px;
    border-radius: 50px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    transition: opacity 0.2s ease-in-out;
    position: relative;

    @media screen and (max-width: 767px) {
        right: 0 !important;
        margin: 0 !important;
        width: 100% !important;
        max-width: 206px !important;
    }

    .progressBtn {
        display: none;
    }
    &:disabled {
        .progressBtn {
            display: block;
            position: absolute;
            width: 100%;
            height: 3px;
            background-color: #ccc;
            left: 0;
            top: 0;
            overflow: hidden;
            &::after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                width: 0%;
                border-radius: 4px;
                animation: loading 5s linear infinite;
            }
        }
    }
    .arrow {
        animation-name: flickIcon;
        animation-duration: 1.4s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-fill-mode: both;
        margin-left: 10px;
    }
    &:disabled {
        opacity: 0.6;
        pointer-events: none;
        cursor: default;
    }
    &:hover {
        opacity: 0.8;
    }
}

.disable-without-opacity {
    &:disabled {
        opacity: 1;
        pointer-events: none;
        cursor: default;
    }
}

.thank-you-step {
    display: flex;
    position: relative;
    flex-direction: row;
    transform: translate(0%, 0px);
    transition: transform 0.2s ease;
    will-change: transform;
    // justify-content: flex-end;

    .thank-you-inner-step {
        flex-shrink: 0;
        width: 100%;
        padding: 0 2px;
    }
}
.extra-steps {
    display: flex;
    position: relative;
    flex-direction: row;
    transform: translate(0%, 0px);
    transition: transform 0.2s ease;
    will-change: transform;
    // justify-content: flex-end;
}
.extra-step-inner {
    flex-shrink: 0;
    width: 100%;
    padding: 0 2px;
}
.field-description {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
    text-align: center;
}
.form-field {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 23px;
    .field-label {
        margin-bottom: 5px;
        font-size: 14px;
        &.as-title {
            font-size: 20px;
            font-weight: bold;
        }
    }
    .error-message {
        font-size: 12px;
        margin-top: 5px;
        color: #ff4242;
        font-weight: 300;
        position: absolute;
        bottom: 0;
        left: 0;
        white-space: nowrap;
    }
    &.error {
        input,
        select,
        textarea {
            border-color: #ff4242;
        }
    }
}

.tooltip {
    background: none;
    opacity: 1;
    max-width: 300px;
    & > div {
        & > div {
            &:first-child {
                border-bottom-color: grey;
            }
            &:nth-child(2) {
                min-height: unset;
                padding: 3px 10px;
                border: none;
                background-color: grey;
                color: white;
                box-shadow: 0px 2px 10px #00000045;
            }
        }
    }
}

.tooltip-icon {
    display: inline;
    margin-left: 5px;
    svg {
        width: 15px;
        height: 15px;
        fill: #959595;
        display: inline;
    }
}

.form-error {
    text-align: center;
    color: #ff4242;
    font-size: 12px;
    font-weight: 300;
    margin-top: 10px;
    @include lg {
        font-size: 14px;
    }
}

.progress-bar {
    width: 100%;
}

.back-button {
    font-size: 12px;
    font-weight: 300;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin: 0 auto;
    margin-top: 20px;
    position: absolute;
    left: 20px;
    bottom: 10px;
    .arrow-back {
        box-sizing: border-box;
        position: relative;
        display: block;
        width: 14px;
        height: 14px;
        border: 2px solid transparent;
        border-radius: 100px;
        margin-right: 3px;
    }

    .arrow-back::after {
        content: "";
        display: block;
        box-sizing: border-box;
        position: absolute;
        width: 8px;
        height: 8px;
        border-bottom: 2px solid;
        border-left: 2px solid;
        transform: rotate(45deg);
        right: 0px;
        top: 1px;
    }
}

.form {
    .inner {
        min-height: 352px;
        overflow: hidden !important;
    }
}

.field-note {
    font-size: 12px;
    color: #999;
    padding-top: 3px;
}

@keyframes flickIcon {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(7px);
    }
    100% {
        transform: translateX(0px);
    }
}
.loader {
    circle {
        stroke: #ff870b;
    }
}
.line-1 {
    width: 65%;
    height: 38px;
    background-color: #d9d9d9;
    margin-bottom: 5px;
}
.line-2 {
    width: 80%;
    height: 26px;
    background-color: #ececec;
    margin-bottom: 5px;
}
.line-3 {
    width: 70%;
    height: 20px;
    background-color: #f4f4f4;
    margin-bottom: 5px;
}
.warningMessage {
    background-color: #f2f2f2;

    color: #333;
    padding: 10px;
    margin-bottom: 15px;
    h1 {
        @media screen and (max-width: 640px) {
            line-height: 1.4;
        }
    }
    .description {
        margin: 5px 0 !important;
        text-align: center;
        color: #666;
        // font-size: 15px;
        @media screen and (max-width: 640px) {
            text-align: left;
            padding-left: 30px;
        }
    }
}
.submittingLabel {
    margin-left: 8px;
    display: inline-block;
    font-size: 13px;
}
.tagGroup {
    background: #fcfcfc;
    border: 1px solid #ccc;
    border-top: 0;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: inset 0 0 10px 0 rgba(100, 110, 120, 0.2);
    .field-label {
        font-size: 14px !important;
    }
}
.secondServiceField {
    font-size: 14px !important;
    min-height: 40px !important;
    background-color: #fff !important;
}
.tagGroupLabel {
    color: #fff;
    display: inline-block;
    padding: 2px 12px;
    border-radius: 20px;
    margin-bottom: 10px;
}
.tagGroupLabelWrapper {
    // max-width: 600px;
    margin: 0 auto;
}
.serviceListWrapper {
    & > div {
        box-shadow: 0 0 6px 3px #eee;
        margin-bottom: 15px;
    }
}
.serviceLabel {
    border: 1px solid #ccc;
    background-color: #fff;
    cursor: pointer;
    padding: 10px 12px;
    position: relative;
    font-weight: 700;
    &::before {
        display: block;
        content: "";
        width: 15px;
        height: 15px;
        border-radius: 3px;
        border: 1px solid var(--checkbox-color);
        margin-right: 10px;
    }
    &::after {
        width: 12px;
        height: 12px;
    }
    &.activeService {
        &::before {
            display: block;
            content: "";
            background-color: var(--checkbox-color);
        }
        &::after {
            position: absolute;
            display: block;
            content: "";
            background: url("./tick.svg") no-repeat;
            left: 14px;
            top: 16px;
        }
    }
}

.category-icon {
    :first-child {
        display: block;
        width: 25px;
        height: 25px;
        margin-right: 5px;
        filter: brightness(0);
    }
}

// .with-first-round-buyer {
//     width: calc(100% - (var(--margin) * 2)) !important;
//     position: relative;
//     margin-bottom: var(--margin) !important;

//     &::after {
//         content: "";
//         z-index: 0;
//         position: absolute;
//         inset: calc(var(--margin) * -1);
//         border: 1px solid #c4c4c4;
//         border-radius: 5px;
//     }
// }

.buyer-logo {
    z-index: 1;
    position: relative;
    // box-shadow:
    //     var(--margin) 0 rgb(255, 255, 255),
    //     calc(-1 * var(--margin)) 0 rgb(255, 255, 255);
}

.hidden {
    display: none;
}
