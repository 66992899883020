
            @import 'src/styles/mixins.scss';
        
.container {
    transition: all .3s ease;
    &:hover {
        box-shadow: 0px 4px 6px -2px #00000026;
    }
}

.link{
    &::before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
}
