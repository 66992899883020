
            @import 'src/styles/mixins.scss';
        
.otp-field {
    padding-bottom: 30px;
    .error-message {
        text-align: center;
        left: 50% !important;
        transform: translateX(-50%);
    }
    @include lg {
        padding-bottom: 40px;
    }
}

.button {
    display: block;
    margin: 0 auto;
    border-bottom: 1px solid black;
    padding-bottom: 2px;
    line-height: 1;
    font-weight: 300;
    margin-top: 15px;
}

.phone-number-modal-wrapper {
    // display: flex;
    align-items: center !important;
}

.otpContainer {
    @include handleAllBreakpoints("color", "thankYouTextColor", "black");

    .phoneNumber {
        color: var(--primary, #000);
    }

    .info-message {
        @include handleAllBreakpoints("color", "thankYouTextColor", "grey");
    }
}
