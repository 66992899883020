
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";
.linkColor {
    color: #47a1e2;
}
// .mainSection {
//     background-color: #f6f6f6;
// }
 
.container {
    @include handleAllBreakpoints("background-color", "phoneNumberBackgroundColor", "#f6f6f6");
    h2 {
        @include handleAllBreakpoints("color", "phoneNumberTextColor", "#000");
    }


    .linkColor {
        @include handleAllBreakpoints("color", "phoneNumberLinkColor", "#47a1e2");
    }
}

.orStyles {
    @include handleAllBreakpoints("color", "stepTitleColor", "var(--global-form-typography-firstStepTitle-color, #7f7f7f)");
    max-width: 90%;
}