
            @import 'src/styles/mixins.scss';
        
.form {
    width: 100%;
    padding: 40px 1rem;
    .steps-inner {
        flex-grow: 1;
        padding-bottom: 20px;
    }
    h1 {
        &.step-title {
            font-size: 2.6rem;
            font-weight: bold !important;
            margin: 0 0 1rem 0;
            @include lg {
                font-size: 1.5rem !important;
                line-height: 1.3;
            }
        }
    }
    .step-title {
        text-align: left;
        font-size: 24px !important;
        font-weight: 700 !important;
        margin-bottom: 10px;
        color: black !important;
    }
    .label-as-step-title {
        text-align: center;
        font-size: 20px !important;
        font-weight: 500 !important;
        margin-top: -20px;
        margin-bottom: 25px !important;
        @include lg {
            margin-right: -50px;
            margin-left: -50px;
        }
    }
    .step-description {
        text-align: left;
        font-weight: 300;
        margin-bottom: 2rem;
        font-size: 14px;
        @include lg {
            font-size: 18px;
        }
    }
    .fields-wrapper {
        margin-top: 1.5rem;
    }
    .form-button {
        max-width: unset !important;
        margin: 0 auto !important;
        margin-top: 5px !important;
        border-radius: 0 !important;
        background-color: #ff870b !important;
        transition: all 0.2s ease-in-out;
        color: black !important;
        border-radius: 4px !important;
        @apply shadow-lg;

        svg {
            path {
                fill: black;
            }
        }
        @include lg {
            // max-width: 450px !important;
            background-color: #ff870b !important;
            border-radius: 4px !important;
        }
        &:hover {
            @apply shadow;
        }
    }
    .form-field {
        // margin-top: 2rem;
        max-width: unset;
        margin: 0 auto;
        margin-bottom: 0.5rem;
        // padding-left: 10px;
        // padding-right: 10px;
        label {
            margin-left: 7px !important;
        }
        input,
        select {
            background-color: white;
        }
        @include lg {
            // max-width: 450px;
        }
    }
    .field-note {
        margin-left: 7px;
    }
    .field-error-message {
        left: 7px;
    }
    .field {
        &:not(.radio-field) {
            // margin: 0 -10px;
            border-radius: 40px;
            background-color: white;
        }
    }
    .tcpa {
        margin-bottom: 10px;
    }
    @include lg {
        width: 100%;
        padding: 40px;
    }
}

.offer-bg-image {
    background-image: url("https://cdn-staging.trafficbox.com/attachments/2024/7/8f93dfbf-8f0d-4953-86e3-8981aea792a1.jpg");
    background-size: cover;
    background-color: #f5f4ef;
    position: relative;
    &::before {
        content: "";
        height: 100%;
        top: 0;
        position: absolute;
        background-color: #f5f4ef;
        z-index: 1;
    }
}
