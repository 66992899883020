
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.checkbox-wrapper {
    display: flex;
    position: relative;
    overflow: hidden;
    transition: all 0.2s ease;
    font-size: 10px;
    font-weight: 400;
    min-height: 20px;
    align-items: flex-start;

    input {
        display: none;

        &:checked + label {
            .box {
                svg {
                    path {
                        stroke-dashoffset: 0;
                        stroke: white;
                    }
                }

                background-color: rgb(101, 101, 255);
            }
        }
    }

    .box {
        background-color: white;
        transition: all 0.2s ease;
        border: 1px solid #bcbcbc;
        min-width: 15px;
        height: 15px;
        padding: 3px;
        border-radius: 3px;
        margin-top: 3px;
        cursor: pointer;
        transition: all 0.2s ease;
        margin-right: 5px;

        svg {
            pointer-events: none;
            path {
                fill: none;
                stroke: rgb(101, 101, 255);
                stroke-width: 10px;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-dasharray: 100;
                stroke-dashoffset: 101;
                transition: all 350ms cubic-bezier(1, 0, 0.37, 0.91);
            }
        }
    }
}
.tcpa {
    font-size: 9px;
    margin-top: 10px;
    font-weight: 300;
    a {
        color: #334bff;
        text-decoration: underline;
    }
}
