
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.serviceLabel {
    border: 1px solid blue;
    cursor: pointer;
    padding: 10px 12px;
    position: relative;
    margin-bottom: 8px;
    font-weight: 700;
    &::before {
        display: block;
        content: "";
        width: 15px;
        height: 15px;
        border-radius: 3px;
        border: 1px solid var(--checkbox-color);
        margin-right: 10px;
    }
    &::after {
        width: 12px;
        height: 12px;
    }
    &.activeService {
        &::before {
            display: block;
            content: "";
            background-color: var(--checkbox-color);
        }
        &::after {
            position: absolute;
            display: block;
            content: "";
            background: url("./tick.svg") no-repeat;
            left: 14px;
            top: 16px;
        }
    }
}
.category-icon {
    :first-child {
        display: block;
        width: 25px;
        height: 25px;
        margin-right: 5px;
    }
}
.buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    @include sm {
        flex-direction: row;
        gap: 20px;
    }
    button {
        background-color: black;
        color: white;
        width: 100%;
        min-height: 50px;
        border-radius: 3px;
        height: 50px;
        transition: opacity 0.2s ease-in-out;
        @include sm {
            width: 50%;
        }
        &:disabled {
            opacity: 0.6;
            pointer-events: none;
            cursor: default;
        }
        &:hover {
            opacity: 0.8;
        }
    }
}

.secondServiceFormWrapper {
    background-color: white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.17);
    padding: 30px 10px;
    border-radius: 8px;
    position: relative;
    max-width: 700px;
    width: 100%;
    min-height: 500px;
    margin: 50px 0;
    z-index: 100;
    @include md {
        margin: 50px auto;
        padding: 30px 20px;
    }
}
.secondServiceFormInnerWrapper {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
}
.secondServiceForm {
    // box-shadow: none !important;
    // background-color: var(--mainBackground-color) !important;
    // padding: 15px 10px !important;
    // border-radius: 0 !important;
    // border: 1px solid var(--mainBorder-color) !important;
    // border-top: 0;

    .step1:nth-child(1) .field-wrapper:nth-child(1) > label {
        text-align: left;
        color: #000;
        margin-bottom: 5px;
    }
    .step1 .field-wrapper > label {
        font-weight: 700;
        color: #000;
        font-size: 16px;
        @media screen and (max-width: 600px) {
            font-size: 14px;
        }
    }
}

.field-wrapper {
    margin-top: 5px;
    margin-bottom: 0;
}

.radio-field {
    display: block;

    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 0;
    @include md {
        gap: 10px;
    }

    & > div {
        display: flex;
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: auto !important;
        }
        width: 100% !important;
        @include md {
            width: 49% !important;
        }
        input {
            &:checked + label {
                color: var(--textSelectedRadio-color);
                background-color: var(--selectedRadio-color);
            }
        }
        label {
            border-radius: 8px;

            border: 1px solid black;
            background-color: #fff;

            text-transform: capitalize;

            &:hover {
                background-color: var(--hoverRadio-color);
                color: var(--textHoverRadio-color);
            }
            img {
                display: none;
            }
            span {
                font-size: 14px;
            }
        }
    }
}
.checkbox-field {
    & > div:nth-of-type(2) {
        width: 100%;
        input {
            &:checked + label {
                font-weight: 500;
                color: var(--textSelectedCheckbox-color);
                background-color: var(--selectedCheckbox-color);
            }
        }

        > div:hover {
            color: var(--textHoverCheckbox-color);
            background-color: var(--hoverCheckbox-color);
        }
    }

    // & > div {
    //     width: 100%;
    //     margin-bottom: 15px;
    //     display: flex;
    //     input {
    //         &:checked + label {
    //             font-weight: 500;
    //             color: var(--textSelectedCheckbox-color);
    //             background-color: var(--selectedCheckbox-color);
    //         }
    //     }
    //     label {
    //         border: 1px solid #d0d0d0;

    //         color: black;
    //         font-size: 14px;
    //         display: flex;
    //         align-items: center;
    //         text-transform: capitalize;
    //         @include md {
    //             min-height: 54px;
    //         }
    //     }
    //     &:hover {
    //         color: var(--textHoverCheckbox-color);
    //         background-color: var(--hoverCheckbox-color);
    //     }
    // }
}
.errorMessage {
    color: $error;
    font-size: 12px;
}
.select {
    background-color: #fff;
}
