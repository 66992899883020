
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.isSecondServiceFormMultipleSelect {
    font-size: 14px !important;
    min-height: 40px !important;
}
.field {
    border: 1px solid #d0d0d0;
    color: black;
    padding: 10px 15px;
    border-radius: 3px;
    font-size: 16px;
    min-height: 50px;
    &[type="number"] {
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        -moz-appearance: textfield;
    }

    &:focus,
    &:focus-within {
        outline: none;
        border-color: grey;
    }

    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px white inset !important;
        -webkit-text-fill-color: grey !important;
    }

    &:autofill {
        -webkit-box-shadow: 0 0 0px 1000px white inset !important;
        -webkit-text-fill-color: grey !important;
    }

    &::-webkit-input-placeholder {
        /* Edge */
        color: grey;
        font-weight: lighter;
        @apply font-light;
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: grey;
        font-weight: lighter;
        @apply font-light;
    }

    &::-ms-clear {
        // remove clear button in IE
        display: none;
    }

    &::placeholder {
        color: grey;
        font-weight: lighter;
        @apply font-light;
    }

    // @apply appearance-none;
}
div.moreOptions {
    background-color: #ffbcb2;
    color: #070707;
    border-color: #ffbcb2 !important;
    border-radius: 4px;
    width: 282px !important;
    height: 50px !important;
    text-align: center;
    font-size: 18px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    margin-top: 15px;
    &:hover {
        border-color: #e3a9a0 !important;
        background-color: #e3a9a0 !important;
    }
}
.radio,
.checkbox {
    border: none;
    padding: 0;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    overflow-x: hidden;

    .option {
        text-align: center;
        margin-bottom: 10px;
        width: 100%;
        position: relative;
        input {
            visibility: hidden;
            user-select: none;
            position: absolute;
            left: -100rem;
        }
        label {
            text-align: center;
            width: 100%;
            display: block;
            padding: 10px 20px;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            display: inline-block;
            img {
                color: transparent;
                pointer-events: none;
                position: relative;
                height: auto;
            }
            .with-tooltip {
                position: absolute;
                background: #686565;
                color: #fff !important;
                padding: 3px 8px;
                border-radius: 5px;
                top: -10px;
                left: 50%;
                opacity: 0;
                transition: opacity 0.1s;
                white-space: nowrap;
                font-size: 12px;
                z-index: 4;
                line-height: 1rem;
                transform: translate(-50%, -100%);
                &::after {
                    content: " ";
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    margin-left: -5px;
                    border: 5px solid transparent;
                    border-top-color: #686565;
                }
            }
            &:hover {
                opacity: 1;
                .with-tooltip {
                    opacity: 1;
                    display: unset;
                }
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.checkbox {
    .option {
        input {
            &:checked + label .box svg path {
                stroke-dashoffset: 0;
            }
            &:checked + label .box {
                background-color: white;
            }
        }
        label {
            display: flex;
            align-items: center;
            min-height: 46px;
            .box {
                display: block;
                border: 1px solid black;
                width: 15px;
                height: 15px;
                border-radius: 1px;
                cursor: pointer;
                transition: all 0.2s ease;
                margin-right: 15px;
                padding: 2px;

                svg {
                    pointer-events: none;
                    path {
                        fill: none;
                        stroke: white;
                        stroke-width: 6px;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-dasharray: 100;
                        stroke-dashoffset: 101;
                        transition: all 350ms cubic-bezier(1, 0, 0.37, 0.91);
                    }
                }
            }
        }
    }
}

.textarea {
    appearance: none;
}

.selectFieldTitle {
    font-weight: 700;
    padding-bottom: 10px;
}
.multipleSelectLabel {
    background: #fff;
    background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' fill='none' viewBox='0 0 8 8' %3E%3Cpath fill='%236D7577' fillRule='evenodd' d='M6.482 2.964a.4.4 0 01.31.652l-2.797 3.44a.4.4 0 01-.62 0L.575 3.615a.4.4 0 01.31-.652h5.596z' clipRule='evenodd' %3E%3C/path%3E%3C/svg%3E");
    background-position-x: 94%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    cursor: pointer;
    padding: 10px 15px;
    padding-right: 22px;
    border-radius: 3px;
    border: 1px solid #d0d0d0;
    min-height: 50px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.4);
    font-weight: 300;
}
.multiSelectInput {
    width: 95%;
    display: block;
    min-height: 40px;
    &:focus {
        outline: none;
    }
}
.multipleSelectLabelActive {
    color: black;
    font-weight: 400;
}
.multipleSelect {
    position: absolute;
    z-index: 10;
    background-color: #fff;
    border: 1px solid #d0d0d0;
    > div {
        margin-bottom: 0 !important;
        label {
            border: none !important;
            border-bottom: 1px solid #d0d0d0 !important;
        }
    }
}
.select {
    background: transparent;
    background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' fill='none' viewBox='0 0 8 8' %3E%3Cpath fill='%236D7577' fillRule='evenodd' d='M6.482 2.964a.4.4 0 01.31.652l-2.797 3.44a.4.4 0 01-.62 0L.575 3.615a.4.4 0 01.31-.652h5.596z' clipRule='evenodd' %3E%3C/path%3E%3C/svg%3E");
    background-position-x: 94%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    cursor: pointer;
    padding-right: 22px;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
    width: 100%;
    &::-ms-expand {
        display: none;
    }
    &.empty {
        color: rgba(black, 0.4);
        font-weight: 300;
    }
}

.otp-field {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
    margin: 0 auto;
    margin-top: 30px;
    width: auto;
    width: fit-content;
    .otp-input {
        border-radius: 4px;
        width: 45px;
        height: 45px;
        font-size: 24px;
        padding: 0;
        text-align: center;
        margin: 0 10px;
        overflow: visible;
        cursor: text;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid grey;
        background-color: #fff;
        color: #333;
        &.focused {
            box-shadow:
                rgba(0, 0, 0, 0) 0px 0px 0px 0px,
                rgba(1, 150, 237, 0.36) 0px 0px 0px 4px,
                rgba(0, 0, 0, 0) 0px 0px 0px 0px,
                rgba(64, 68, 82, 0.16) 0px 0px 0px 1px,
                rgba(0, 0, 0, 0) 0px 0px 0px 0px,
                rgba(0, 0, 0, 0) 0px 0px 0px 0px,
                rgba(0, 0, 0, 0) 0px 0px 0px 0px;
            border-color: transparent;
        }
        &.error {
            border-color: $error;
            &.focused {
                box-shadow:
                    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
                    rgba($error, 0.36) 0px 0px 0px 4px,
                    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
                    rgba(64, 68, 82, 0.16) 0px 0px 0px 1px,
                    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
                    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
                    rgba(0, 0, 0, 0) 0px 0px 0px 0px;
            }
        }
        &.disabled {
            opacity: 0.5;
            user-select: none;
            pointer-events: none;
        }

        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }
    .otp-hidden-input {
        position: absolute;
        left: 0;
        top: 0;
        height: 45px;
        width: 100%;
        opacity: 0;
        background-color: rgba(grey, 0.4);
        left: 0%;
    }
    .error {
        color: $error;
    }
}

.date-picker {
    width: 100%;
    &.mobile {
        position: relative;
        .text-input {
            user-select: none;
            pointer-events: none;
            touch-action: none;
            width: 100%;
        }
        .date-input {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
            display: block;
            right: 0;
            &::-webkit-calendar-picker-indicator {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: auto;
                height: auto;
                color: transparent;
                background: transparent;
            }
            -webkit-appearance: none;
            -moz-appearance: none;
        }
    }
    &.desktop {
        justify-content: space-between;
        margin: 0 -5px;
        width: calc(100% + 10px);
        .select {
            width: 33.3%;
            margin: 0 5px;
            padding-left: 10px;
            padding-right: 15px;
            &.day {
                width: 20%;
                @include lg {
                    width: 33%;
                }
            }
            &.month {
                width: 45%;
                min-width: 126px;
                @include lg {
                    width: 33%;
                }
            }
            &.year {
                width: 35%;
                min-width: 66px;
                @include lg {
                    width: 33%;
                }
            }
        }
    }
}
.search-wrapper {
    width: 100%;
    position: relative;
    padding-right: 15px;
    display: block;
    input {
        display: block;
        width: 100%;
    }
    svg {
        position: absolute;
        top: 12px;
        left: 22px;
    }
}
.radio-search {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    margin: 0 10px 10px 5px;
    padding: 2px 0 2px 47px;
    outline: none;
    height: 40px;
    color: #9b9b9b;
    width: min(311px, calc(100% - 20px));
    @apply font-light;

    &::-webkit-input-placeholder {
        /* Edge */
        color: #9b9b9b;
        @apply font-light;
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #9b9b9b;
        @apply font-light;
    }
    &::placeholder {
        @apply font-light;
        color: #9b9b9b;
    }
}

.streetAddressFieldWrapper {
    position: relative;
}

.address-details-button {
    border-bottom: 1px solid #3030c5;
    color: #3030c5;
    background: none;
    padding: 0;
    display: inline-block;
}
.addressDetails {
    color: #333;
    background-color: #fff2d0;
    line-height: 1;
    display: inline-block;

    span {
        padding-right: 5px;
    }
}
.zipcode-details-inside {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0;
    background-color: transparent;
}
.addressDetailsInputs {
    // padding: 15px 15px 5px;
    margin-top: 10px;
    background: #fff;
    border: 1px solid #e3e3e3;
    // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.error {
    color: $error;
    font-size: 12px;
    margin-top: 5px;
    font-weight: 300;
    white-space: nowrap;
}

.addressSubTitle {
    color: #333;
}
.loading {
    position: absolute;
    top: 10px;
    right: 10px;
}
.streetAddressField {
    position: relative;
    input {
        width: 100%;
    }
}
.btnUpdate {
    font-size: 13px;
    border: 1px solid #27aae1;
    border-radius: 4px;
    background-color: #fff;
    color: #27aae1;
    cursor: pointer;
    padding: 5px;
    min-height: 35px;
    display: inline-block;
    width: 80px;
    margin-top: 8px;
    margin-right: 15px;
}
.btnCancelUpdate {
    font-size: 13px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    // min-height: 35px;
    display: inline-block;
    // width: 80px;
    margin-top: 8px;
    border-bottom: 1px solid #666;
    color: #666;
}
.expandableField {
    min-height: auto;
    height: 35px;
    padding: 5px 10px;
    width: 120px;
}
.fieldLabel {
    color: #666;
}

.btnWrapper {
}
.topSection {
    padding: 10px 15px;
    background-color: #f2f2f2;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    margin-bottom: 3px;
}
.btnSection {
    padding: 0 15px 5px;
}

.getZipCodeLoading {
    text-align: center;
    padding-top: 10px;
}
.getZipCodeLoadSpinner {
    margin: 20px 0 30px;
}

.field-note {
    font-size: 12px;
    color: #999;
    padding-top: 3px;
}
.suggestionsWrapper {
    background-color: #fff;
    border-radius: 0 0 8px 8px;
    // box-shadow: 0 0 10px 10px #ccc;
    border: 1px solid #ccc;
    min-height: 100px;
    padding: 10px 0;
    position: absolute;
    width: 100%;
    z-index: 1;
    top: 50px;
    max-height: 110px;
    overflow-y: auto;
}
.suggestion {
    padding: 5px 8px;
    cursor: pointer;
    &:hover {
        background: #eee;
    }
}
.customLabel {
    padding: 13px 15px 10px;
    border-right: 1px solid #d0d0d0;
    white-space: nowrap;
    min-width: 136px;
    text-align: center;
}
.inputWrapper {
    border: 1px solid #d0d0d0;
    color: black;
    border-radius: 3px;
    font-size: 16px;
    min-height: 50px;
    input {
        border-radius: 0;
        border: none;
    }
}
.labelWithIcon {
    justify-content: flex-start;
}

.range {
    /*********** Baseline, reset styles ***********/
    &[type="range"] {
        -webkit-appearance: none;
        appearance: none;
        background: transparent;
        cursor: pointer;
        width: 100%;
        @include handleAllBreakpoints("max-width", "rangeMaxWidth", "100%");
    }

    /* Removes default focus */
    &[type="range"]:focus {
        outline: none;
    }

    @mixin track {
        @include handleAllBreakpoints("background-color", "rangeTrackBackgroundColor", "#ccc");
        @include handleAllBreakpoints("border", "rangeTrackBorder", "none");
        @include handleAllBreakpoints("border-radius", "rangeTrackBorderRadius", "0");
        @include handleAllBreakpoints("height", "rangeTrackHeight", "7px");
    }

    @mixin thumb {
        @include handleAllBreakpoints("background-color", "rangeThumbBackgroundColor", "#000");
        @include handleAllBreakpoints("border", "rangeThumbBorder", "none");
        @include handleAllBreakpoints("border-radius", "rangeThumbBorderRadius", "1rem");
        @include handleAllBreakpoints("box-shadow", "rangeThumbShadow", "0 0 0px 4px rgba(129, 127, 127, 0.2)");
        @include handleAllBreakpoints("height", "rangeThumbHeight", "30px");
        @include handleAllBreakpoints("width", "rangeThumbWidth", "30px");
        @include handleAllBreakpoints("margin-top", "rangeThumbTopMargin", "-11.5px");
        &:hover {
            @include handleAllBreakpoints(
                "box-shadow",
                "rangeThumbHoverShadow",
                "0 0 0px 6px rgba(129, 127, 127, 0.2)"
            );
            @include handleAllBreakpoints("height", "rangeThumbHoverHeight", "30px");
            @include handleAllBreakpoints("width", "rangeThumbHoverWidth", "30px");
            @include handleAllBreakpoints("border", "rangeThumbHoverBorder", "none");
            @include handleAllBreakpoints("border-radius", "rangeThumbHoverBorderRadius", "1rem");
            @include handleAllBreakpoints("background-color", "rangeThumbHoverBackgroundColor", "#000");
        }
    }

    /******** Chrome, Safari, Opera and Edge Chromium styles ********/
    /* slider track */
    &[type="range"]::-webkit-slider-runnable-track {
        @include track;
        z-index: 0;
    }

    /* slider thumb */
    &[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none; /* Override default look */
        appearance: none;
        @include thumb;
        transition: box-shadow 0.2s ease-in-out;
        z-index: 2;
    }

    // &[type="range"]:focus::-webkit-slider-thumb {
    // outline: 3px solid #000000;
    // outline-offset: 0.125rem;
    // }

    /*********** Firefox styles ***********/
    /* slider track */
    &[type="range"]::-moz-range-track {
        @include track;
        z-index: 0;
    }

    /* slider thumb */
    &[type="range"]::-moz-range-thumb {
        border: none; /*Removes extra border that FF applies*/
        @include thumb;
        transition: box-shadow 0.2s ease-in-out;

        z-index: 2;
    }

    // &[type="range"]:focus::-moz-range-thumb {
    // outline: 3px solid #000000;
    // outline-offset: 0.125rem;
    // }
}

.range-value {
    @include handleAllBreakpoints("color", "rangeValueTextColor", "#000000");
    @include handleAllBreakpoints("font-size", "rangeValueTextSize", "30px");
    @include handleAllBreakpoints("font-weight", "rangeValueTextWeight", "600");
    @include handleAllBreakpoints("text-align", "rangeValueTextAlign", "center");
    @include handleAllBreakpoints("margin", "rangeValueTextMargin", "20px 0");
    @include handleAllBreakpoints("padding", "rangeValueTextPadding", "0");
    @include handleAllBreakpoints("background-color", "rangeValueTextBackgroundColor", "transparent");
    @include handleAllBreakpoints("border", "rangeValueTextBorder", "none");
    @include handleAllBreakpoints("border-radius", "rangeValueTextBorderRadius", "0");
    @include handleAllBreakpoints("box-shadow", "rangeValueTextShadow", "none");
}

.error-message {
    font-size: 12px;
    color: #ff4242;
    font-weight: 300;
    position: relative;
    bottom: 0;
    left: 0;
    white-space: nowrap;
}
.field-note {
    font-size: 12px;
    color: #999;
    padding-top: 3px;
}
.errorBorder {
    border: 1px solid #ff4242;
}
.no-background {
    background-color: unset !important;
}
