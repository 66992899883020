
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.form-button {
    max-width: 350px !important;
    margin: 20px auto 0;
    border-radius: 10px;
    color: #000 !important;
    background-color: #ff9800 !important;
    font-weight: 700;
    svg {
        path {
            fill: #000 !important;
        }
    }
}
.tcpaClassName {
    // max-width: 350px !important;
    // margin: 50px auto 0;
    div {
        font-weight: 300 !important;
    }
}
.piiCompletionFormWrapper {
    background-color: white;
    border-radius: 8px;
    position: relative;
    width: 100%;
    min-height: 500px;
    @include md {
        padding: 20px 100px;
    }
    label {
        font-weight: 700 !important;
    }
    [class*="pii-completion-missing-fields"] {
        .field-wrapper {
            @include md {
                max-width: 350px;
            }
            margin: 15px auto 0;
            .formFieldLabelClassName {
                font-weight: 500;
                margin-bottom: 10px;
                font-size: 1rem;
                justify-content: center;
            }
        }
    }
}

.field-wrapper {
    margin-top: 15px;
    place-content: start;
    margin-bottom: 0;
    [class*="field-description"] {
        display: none;
    }
    .formFieldLabelClassName {
        min-height: 42px;
        display: flex;
        align-items: center;
    }
}

.all {
    border-radius: 10px;
    input,
    select,
    textarea {
        border-radius: 10px;
    }

    [class*="multiple"] {
        border-radius: 10px;
    }
}

.fieldsWrapperClassName {
    --min: 280px;
    --gap: 2rem;
    margin-bottom: 2rem;
    display: grid;
    grid-gap: 0 var(--gap);
    /* min() with 100% prevents overflow
    in extra narrow spaces */
    grid-template-columns: repeat(auto-fit, minmax(min(100%, var(--min)), 1fr));
}

.pii-missing-field-headline {
    @apply text-center max-w-[700px] mb-4;
    h1 {
        @apply font-bold text-lg md:text-3xl mb-1 md:mb-3;
    }
    h2 {
        @apply font-bold text-base md:text-2xl mb-4;
    }
}

.pii-other-fields-headline {
    @apply text-center max-w-[700px] mb-2 mt-2 p-2;
    background-color: aliceblue;
    h2 {
        @apply font-bold text-lg md:text-3xl mb-5;
    }
}
